.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.headerCol {
}

.pmain {
  text-align: left;
}

.home {
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
}
.homeBlack {
  width: auto;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 0, 0);
}

.myfooterParent {
  display: flex;
  flex-flow: column no-wrap;
  justify-content: space-between;
  margin-top: 3vh;
  margin-bottom: 2vh;
   {
    /* margin-top: 6vh;
  position: absolute;
  left: 0;
  right: 0;*/
  }
}
.myfooter {
  margin: auto;
  align-self: flex-end;
}
.image1 {
  width: 95%;
}

.photo1 {
  position: absolute;
  height: 200px;
  width: 200px;
}

.photo2 {
  position: relative;
  width: 30vw;
  height: auto;
}

.firstCard {
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  background-color: black;
  min-height: 45vh;
  min-width: 50vw;
  max-width: 50vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}
.secondCard {
  margin: 0;
  padding: 0;
  min-height: 45vh;
  min-width: 50vw;
  max-width: 50vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.thirdCard {
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  background-color: rgb(71, 69, 69);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fourthCard {
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  background-color: grey;
  min-height: 30vh;
  min-width: 50vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.row {
  flex-wrap: "wrap";
  align-items: flex-start;
}
.row-with-gradient {
  width: auto;
  height: auto;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  margin: 0;
  padding: 0;
}
.col {
}

.myCarousel {
  background: rgb(255, 255, 255);
}

.myCarouselItem {
}

/* max-width constrains the width of our carousel to 550, but shrinks on small devices */
.carousel__container {
  max-width: 550px;
  margin: auto;
}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
  padding-left: 25%;
  padding-right: 25%;
}

/* gives us the illusion of spaces between the slides */
.carousel__inner-slide {
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  left: 10px;
  top: 10px;
  background-color: burlywood;
}
.centeredChild {
  justify-content: center;
  align-items: center;
  flex-basis: "100%";
  flex: 250px;
}

.centeredButtons {
  justify-content: center;
  align-items: center;
  flex: 250px;
}

.centered {
  padding-top: 4vh;
  padding-bottom: 2vh;
  display: flex;
  flex-wrap: "wrap";
  flex-direction: "row";
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.centeredBlack {
  height: 80vh; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
.topLeft {
  height: auto; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.topLeftBlack {
  height: 80vh; /* Magic here */
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: black;
}
.topMid {
  height: auto; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.mintonebutton {
  width: 30%;
  height: 30%;
  justify-content: center;
  align-items: center;
}
.tfbutton {
  width: 15%;
  height: 30%;
  justify-content: center;
  align-items: center;
}
.aboutParagraph-left {
  width: 60%;
  height: auto;
  justify-content: left;
}

.aboutParagraph-right {
  width: 60%;
  height: auto;
  justify-content: left;
  float: right;
}

.buffer20row {
  width: auto;
  height: 20vw;
  background-color: white;
}
.buffer20row-black {
  width: auto;
  height: 20vw;
  background-color: black;
}
