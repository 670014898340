.font-Poppins {
  font-family: Poppins, sans-serif;
  color: black;
  font-size: 42px;
  font-weight: bold;
  line-height: 64px;
}
.font-Poppins-white {
  font-family: Poppins, sans-serif;
  color: white;
  font-size: 42px;
  font-weight: bold;
  line-height: 64px;
}

.font-Poppins-small {
  font-family: Poppins, sans-serif;
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: normal;
  line-height: 32px;
}
.font-Poppins-small-white {
  font-family: Poppins, sans-serif;
  color: white;
  font-size: 18px;
  font-weight: normal;
  line-height: 28px;
}
.font-Kahlua {
  font-family: Kahlua, sans-serif;
  font-size: 500px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
